import {
  Box,
  Flex,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

const Logo = (props: any) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={60}
      height={60}
      viewBox="0 0 300.000000 298.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <metadata>Created by potrace 1.10</metadata>
      <g
        transform="translate(0.000000,298.000000) scale(0.050000,-0.050000)"
        fill="#444444"
        stroke="none"
      >
        <path
          d="M5910 5940 c6 -10 -8 -23 -30 -30 -22 -7 -35 -22 -28 -33 7 -12 27
-6 50 15 21 19 39 31 39 26 1 -4 6 -29 11 -55 7 -40 2 -46 -32 -37 -22 6 -35
2 -29 -8 6 -10 24 -18 40 -18 16 0 29 -9 29 -20 0 -11 9 -20 19 -20 19 0 20
178 1 181 -5 1 -26 5 -45 9 -19 3 -31 -1 -25 -10z"
        />
        <path
          d="M2209 4755 c-57 -39 -96 -80 -92 -96 3 -16 0 -22 -8 -14 -23 22 -69
-25 -69 -71 0 -22 -6 -35 -13 -28 -7 8 -32 -12 -55 -43 -23 -31 -84 -105 -134
-164 -167 -196 -198 -306 -120 -416 64 -90 140 -143 205 -143 l57 0 0 -513 0
-513 -60 83 c-71 97 -73 129 -20 246 124 273 -62 683 -371 820 -245 108 -969
105 -1105 -5 -152 -123 -144 -68 -144 -1051 l0 -880 55 -72 c122 -161 133
-164 529 -172 636 -11 857 67 1056 377 80 125 100 126 100 3 0 -105 77 -277
119 -268 13 4 19 -3 12 -13 -6 -10 18 -40 54 -65 l65 -47 1650 -5 c1579 -5
1653 -4 1719 32 342 183 221 623 -170 623 -419 0 -517 28 -420 119 28 26 45
55 39 64 -6 9 -2 17 9 17 31 0 300 341 354 449 84 169 34 327 -137 432 -66 41
-923 49 -1115 10 l-72 -15 -26 117 c-73 335 -244 408 -499 214 l-88 -67 -35
55 c-158 252 -415 268 -559 35 -49 -79 -54 -279 -9 -339 39 -52 37 -96 -11
-181 -36 -64 -40 -108 -40 -461 0 -246 -7 -389 -20 -389 -11 0 -20 -13 -20
-30 0 -22 -22 -30 -80 -30 l-80 0 0 709 0 710 73 -3 c141 -5 275 193 230 341
-27 89 -193 304 -245 318 -21 5 -38 18 -38 29 0 10 11 13 25 6 14 -7 11 -1 -6
14 -17 14 -51 60 -76 101 -124 203 -275 265 -414 170z m223 -269 c43 -73 143
-198 223 -277 108 -107 145 -158 145 -197 l0 -53 -80 45 c-45 25 -126 80 -181
121 -55 41 -104 75 -109 75 -6 0 -10 -463 -10 -1030 l0 -1030 330 0 330 0 0
560 0 560 110 0 110 0 0 -560 0 -560 185 0 185 0 0 470 0 470 -85 0 -85 0 0
92 0 91 85 -6 85 -7 -5 160 -5 160 102 65 c56 36 108 65 115 65 7 0 13 -101
13 -224 l0 -224 105 5 105 6 0 -92 0 -91 -110 0 -110 0 0 -470 0 -470 155 0
156 0 299 344 c165 190 353 401 417 470 l118 126 -393 0 -392 0 0 90 0 90 530
0 530 0 -1 -75 c0 -70 -548 -754 -770 -961 -87 -82 -72 -84 537 -84 l586 0 -4
-90 -4 -91 -1702 -4 -1702 -5 0 1139 0 1138 -55 -51 c-30 -29 -113 -89 -185
-134 l-130 -81 -6 49 c-5 38 25 81 136 192 78 79 178 206 222 281 99 169 114
170 210 3z m-950 -811 c295 -145 336 -597 66 -720 -63 -28 -61 -47 9 -71 346
-120 344 -659 -3 -838 -95 -49 -127 -54 -374 -60 -148 -4 -367 -9 -485 -12
l-215 -5 0 887 0 887 445 -7 c439 -6 446 -7 557 -61z m1818 -65 l0 -130 -110
0 -110 0 0 130 0 130 110 0 110 0 0 -130z m1045 -772 c144 -31 145 -24 -36
-228 -191 -214 -189 -214 -189 52 l0 223 55 -12 c30 -6 107 -22 170 -35z"
        />
        <path
          d="M716 3273 l-6 -273 270 0 c398 0 494 42 515 226 26 231 -73 295 -478
309 l-295 11 -6 -273z"
        />
        <path
          d="M715 2788 c-3 -2 -2 -141 1 -308 l6 -303 324 6 c374 8 426 26 497
172 81 169 2 358 -167 401 -72 19 -647 46 -661 32z m588 -283 c47 -47 -17 -75
-194 -82 l-169 -7 0 64 0 64 169 -7 c100 -4 179 -17 194 -32z"
        />
      </g>
    </svg>
  );
};

const Footer = () => {
  return (
    <Box
      paddingTop="0"
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}
    >
      <Flex width="100%" justifyContent="center" alignItems="center">
        <Logo />
        <Text paddingLeft="20px">©2021 BlitzScaling Inc. All rights reserved</Text>
      </Flex>
    </Box>
  );
};
export default Footer;
